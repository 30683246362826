const mockOrder = (service) => {
  let mockOrders = [];
  let order = {
    id: 1,
    status: "unassigned",
    customer: "Ibrahim Alausa",
    user_id: 42,
    gardener: "Abimbola Owolabi",
    location_area: { id: 2, name: "Alagomeji" },
    address: "C7 U3, Jacob Mews Estate",
    phone_number: 8179591037,
    delivery: "2022-03-28",
  };

  switch (service) {
    case "beauty":
      order.rider = "Peter Parker";
      order.type = "standard";
      order.variations = 2;
      break;
    case "cleaning":
      order.cleaners = ["Tola Titus", "Bola Benson"];
      order.type = "light-cleaning";
      order.rooms = 2;
      break;
    case "laundry-pickup":
      order.rider = "Peter Parker";
      order.type = "wash-and-iron";
      order.bags = 2;
      break;
    case "laundry-delivery":
      order.rider = "Peter Parker";
      order.type = "wash-and-iron";
      order.bags = 2;
      break;
    case "meal":
      order.rider = "Peter Parker";
      order.items = 2;
      break;
  }

  mockOrders.push(order);
  return mockOrders;
};

export const parseScheduleOrder = (order, service) => {
  let parsedOrder = {
    id: order.id,
    status: order.status,
    customer: order.customer,
    customer_id: order.user_id,
    gardener: order.gardener,
    phone_number: order.phone_number,
    locationarea: order.location_area,
    address: order.address,
    date: order.delivery,
  };

  switch (service) {
    case "beauty":
      parsedOrder.type = order.type;
      parsedOrder.quantity = order.variations;
      parsedOrder.personnel = [order.rider];
      break;
    case "cleaning":
      parsedOrder.type = order.type;
      parsedOrder.quantity = order.rooms;
      parsedOrder.personnel = order.cleaners;
      break;
    case "laundry-pickup":
      parsedOrder.type = order.type;
      parsedOrder.quantity = order.bags;
      parsedOrder.personnel = [order.rider];
      break;
    case "laundry-delivery":
      parsedOrder.type = order.type;
      parsedOrder.quantity = order.bags;
      parsedOrder.personnel = [order.rider];
      break;
    case "meal":
      parsedOrder.quantity = order.items;
      parsedOrder.personnel = [order.rider];
      break;
    default:
      break;
  }

  return parsedOrder;
};

export const parseScheduleOrders = (service) => {
  return mockOrder(service).map((order) => {
    return {
      ...parseScheduleOrder(order, service),
    };
  });
};
