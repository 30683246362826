<template>
  <el-dialog
    title="Assign A Rider"
    :visible.sync="setShow"
    width="50%"
    @close="closeEvent"
  >
    <eden-table-actions>
      <template slot="actions">
        <location-areas-filter :selected-locations.sync="selectedLocations" />
      </template>
    </eden-table-actions>
    <el-table :data="riders" highlight-current-row @current-change="onSelect">
      <el-table-column width="140">
        <template slot="header">
          <span>Name</span>
        </template>
        <template slot-scope="scope">
          <span>
            {{ scope.row.name }}
          </span>
        </template>
      </el-table-column>
      <el-table-column width="120">
        <template slot="header">
          <span>Company</span>
        </template>
        <template slot-scope="scope">
          <span>
            {{ scope.row.company }}
          </span>
        </template>
      </el-table-column>
      <el-table-column width="140">
        <template slot="header">
          <span>Location Area</span>
        </template>
        <template slot-scope="scope">
          <template v-if="scope.row.locationareas.length">
            <span v-for="(area, index) in scope.row.locationareas" :key="index">
              {{ area.name }}
            </span>
          </template>
          <p v-else>-</p>
        </template>
      </el-table-column>
      <el-table-column width="150">
        <template slot="header">
          <span>Customers Assigned</span>
        </template>
        <template slot-scope="scope">
          <p>{{ scope.row.customers }}</p>
        </template>
      </el-table-column>
      <el-table-column width="120">
        <template slot="header">
          <span>{{ quantityColumn }}</span>
        </template>
        <template slot-scope="scope">
          <p>{{ scope.row.items }}</p>
        </template>
      </el-table-column>
    </el-table>
    <span slot="footer" class="dialog-footer">
      <el-button
        type="primary"
        :loading="assigning"
        :disabled="rider === null"
        @click="assign"
      >
        Assign this rider
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
import LocationAreasFilter from "@/components/Logistics/Schedule/Create/LocationAreasFilter";

export default {
  name: "AssignRider",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    service: {
      type: String,
      default: "",
    },
  },
  components: { LocationAreasFilter },
  data() {
    return {
      selectedLocations: [],
      rider: null,
      assigning: false,
    };
  },
  computed: {
    setShow: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit("update:show", value);
      },
    },
    riders() {
      return [
        {
          id: 2,
          name: "Tola Ayeni",
          locationareas: [
            {
              id: 1,
              name: "Alagomeji",
            },
          ],
          company: "Dellyman",
          customers: 2,
          items: 4,
        },
        {
          id: 3,
          name: "Bola Ige",
          locationareas: [
            {
              id: 1,
              name: "Lekki Phase I",
            },
          ],
          company: "Gokada",
          customers: 0,
          items: 0,
        },
      ];
    },
    quantityColumn() {
      switch (this.service) {
        case "beauty":
          return "No of Variations";
        case "cleaning":
          return "No of Rooms";
        case "laundry-pickup":
          return "No of Bags";
        case "laundry-delivery":
          return "No of Bags";
        case "meal":
          return "No of Meals";
        default:
          return "";
      }
    },
  },
  methods: {
    onSelect(rider) {
      this.rider = rider;
    },
    closeEvent() {
      this.setShow = false;
      this.rider = null;
    },
    assign() {
      this.assigning = true;
      setTimeout(() => {
        this.$emit("assigned", [this.rider.name]);
        this.closeEvent();
        this.assigning = false;
      }, 1500);
    },
  },
};
</script>

<style lang="scss" scoped></style>
