<template>
  <el-dialog
    title="Assign Cleaners"
    :visible.sync="setShow"
    width="70%"
    class="strict"
  >
    <eden-table-actions>
      <template slot="actions">
        <location-areas-filter :selected-locations.sync="selectedLocations" />
      </template>
    </eden-table-actions>
    <el-table
      :data="cleaners"
      @select-all="selectCleaners"
      @selection-change="selectCleaners"
    >
      <el-table-column type="selection" width="55" />
      <el-table-column width="140">
        <template slot="header">
          <span>Name</span>
        </template>
        <template slot-scope="scope">
          <span>
            {{ scope.row.name }}
          </span>
        </template>
      </el-table-column>
      <el-table-column width="140">
        <template slot="header">
          <span>Location Area</span>
        </template>
        <template slot-scope="scope">
          <template v-if="scope.row.locationareas.length">
            <span
              v-for="(location, index) in scope.row.locationareas"
              :key="index"
            >
              {{ location.name }},
            </span>
          </template>
          <p v-else>-</p>
        </template>
      </el-table-column>
      <el-table-column width="200">
        <template slot="header">
          <span>Preferred Days</span>
        </template>
        <template slot-scope="scope">
          <template v-if="scope.row.days.length">
            <span v-for="(day, index) in scope.row.days" :key="index">
              {{ day }},
            </span>
          </template>
          <p v-else>-</p>
        </template>
      </el-table-column>
      <el-table-column width="150">
        <template slot="header">
          <span>Customers Assigned</span>
        </template>
        <template slot-scope="scope">
          <p>{{ scope.row.customers }}</p>
        </template>
      </el-table-column>
    </el-table>
    <span slot="footer" class="dialog-footer">
      <el-button
        type="primary"
        :loading="assigning"
        :disabled="!selectedCleaners.length"
        @click="assign"
      >
        Assign cleaners
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
import LocationAreasFilter from "@/components/Logistics/Schedule/Create/LocationAreasFilter";

export default {
  name: "AssignCleaner",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  components: { LocationAreasFilter },
  data() {
    return {
      loading: false,
      search: "",
      location: "all",
      selectedLocations: [],
      selectedCleaners: [],
      assigning: false,
    };
  },
  computed: {
    setShow: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit("update:show", value);
      },
    },
    cleaners() {
      return [
        {
          id: 1,
          name: "Anjola Inioluwa",
          locationareas: [
            { id: 1, name: "Alagomeji" },
            { id: 2, name: "Chevron" },
          ],
          days: ["monday", "tuesday"],
          customers: 2,
        },
        {
          id: 2,
          name: "Dennis Parker",
          locationareas: [
            { id: 1, name: "Alagomeji" },
            { id: 2, name: "Chevron" },
          ],
          days: ["monday", "tuesday"],
          customers: 2,
        },
      ];
    },
  },
  methods: {
    selectCleaners(cleaners) {
      this.selectedCleaners = [];
      cleaners.forEach((cleaner) => {
        this.selectedCleaners.push(cleaner.name);
      });
    },
    closeEvent() {
      this.setShow = false;
      this.selectedCleaners = [];
    },
    assign() {
      this.assigning = true;
      setTimeout(() => {
        this.$emit("assigned", this.selectedCleaners);
        this.closeEvent();
        this.assigning = false;
      }, 1500);
    },
  },
};
</script>

<style lang="scss" scoped>
.dropdown-search {
  width: 200px;
  &--field {
    padding: 0 10px 10px;
  }

  &--list {
    padding: 0 10px 10px;
    max-height: 250px;
    overflow-y: scroll;

    .location {
      margin-bottom: 10px;
    }
  }
}
</style>
