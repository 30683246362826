<template>
  <div class="schedule--days">
    <el-radio-group v-model="date" class="eden-custom--radio flex">
      <el-radio
        v-for="(date, index) in dates"
        :key="index"
        :label="date"
        class="large"
        @change="setDate"
      >
        <div class="text">
          <span class="title"> {{ getWeekday(date) }}</span>
          <span>{{ formatDate(date, "do m, y") }}</span>
        </div>
      </el-radio>
    </el-radio-group>
  </div>
</template>

<script>
export default {
  name: "ScheduleDates",
  data() {
    return {
      loading: false,
      date: "",
      dates: [],
    };
  },
  created() {
    this.setDates();
  },
  methods: {
    setDates() {
      let currentDayIndex = this.getWeekdayIndex();
      let currentDay = this.getWeekday();
      let currentTime = this.formatTime("hour");

      let stop;

      if (currentDay === "Friday" && currentTime >= 20) {
        stop = 7;
      } else if (currentDayIndex < 5) {
        stop = 5 - currentDayIndex;
      } else {
        stop = 6;
      }

      let tomorrow = this.formatPeriodDate("tomorrow", "y-m-d");
      let nexttwodays = this.formatPeriodDate("nexttwodays", "y-m-d");

      let dates =
        this.getWeekday(tomorrow) === "Sunday" ? [nexttwodays] : [tomorrow];

      for (let i = 1; i < stop; i++) {
        const saturday =
          this.getWeekday(dates[dates.length - 1]) === "Saturday";
        const date = this.getFutureDate({
          date: dates[dates.length - 1],
          unit: "day",
          num: saturday ? 2 : 1,
          format: "y-m-d",
        });
        dates.push(date);
      }
      this.dates = dates;
      this.date = this.dates[0];
      this.setDate();
    },
    setDate() {
      this.$emit("set-date", this.date);
    },
  },
};
</script>

<style lang="scss" scoped>
.schedule--days {
  margin-bottom: 48px;
}
</style>
