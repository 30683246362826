<template>
  <div>
    <div class="is-flex is-align-center">
      <span class="font-base mr-10"
        >{{ selectedOrders.length }}
        {{ formatServiceUnit("order", selectedOrders.length) }} selected</span
      >
      <el-button
        v-if="service === 'cleaning'"
        @click="showAssignCleaner = true"
        type="primary"
        icon="el-icon-plus"
      >
        Assign cleaners
      </el-button>
      <el-button
        v-else
        @click="showAssignRider = true"
        type="primary"
        icon="el-icon-plus"
      >
        Assign rider
      </el-button>
    </div>
    <assign-cleaner
      :show.sync="showAssignCleaner"
      :service="service"
      :selected-orders="selectedOrders"
      @assigned="emitUpdate"
    />
    <assign-rider
      :show.sync="showAssignRider"
      :service="service"
      :selected-orders="selectedOrders"
      @assigned="emitUpdate"
    />
  </div>
</template>
<script>
import AssignCleaner from "@/components/Logistics/Directory/Cleaners/AssignCleaner";
import AssignRider from "@/components/Logistics/Directory/Riders/AssignRider";

export default {
  name: "ScheduleOrdersActions",
  components: { AssignRider, AssignCleaner },
  props: {
    service: {
      type: String,
      default: "",
    },
    selectedOrders: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      showAssignCleaner: false,
      showAssignRider: false,
    };
  },
  methods: {
    emitUpdate(personnel) {
      this.$emit("assign-personnel", { status: "assigned", personnel });
    },
  },
};
</script>

<style lang="scss" scoped></style>
