<template>
  <div>
    <el-tag v-if="status === 'unassigned'" :type="setType('unassigned')"
      >Unassigned
    </el-tag>
    <template v-else>
      <span
        v-for="(person, index) in personnel"
        :key="index"
        class="personnel"
        >{{ person }}</span
      >
    </template>
  </div>
</template>

<script>
export default {
  name: "ScheduleOrderStatus",
  props: {
    status: {
      type: String,
      required: true,
    },
    personnel: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.personnel {
  display: block;

  &:not(:last-child) {
    margin-bottom: 4px;
  }
}
</style>
