<template>
  <div>
    <el-dropdown @command="command" class="more">
      <span class="el-dropdown-link">
        <i class="eden-icon-more"></i>
      </span>
      <el-dropdown-menu slot="dropdown">
        <template v-for="(action, i) in actions">
          <el-dropdown-item
            v-if="allowAccessFor(action.access)"
            :key="i"
            :command="{
              action: action.name,
              component: action.component,
            }"
          >
            {{ action.label }}</el-dropdown-item
          >
        </template>
      </el-dropdown-menu>
    </el-dropdown>
    <component
      :is="action.component"
      :show.sync="action.visibility"
      :type="informationType"
      :order="order"
      @updated="updateAddress"
      @assigned="updatePersonnel"
    />
  </div>
</template>

<script>
export default {
  name: "ScheduleOrderActions",
  components: {
    OrderDeliveryInformationEdit: () =>
      import("@/components/Orders/Order/Address/OrderAddressInformationEdit"),
    AssignCleaner: () =>
      import("@/components/Logistics/Directory/Cleaners/AssignCleaner"),
    AssignRider: () =>
      import("@/components/Logistics/Directory/Riders/AssignRider"),
  },
  props: {
    order: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      action: {
        command: "",
        component: "",
        visibility: false,
      },
    };
  },
  computed: {
    setOrder: {
      get() {
        return this.order;
      },
      set(value) {
        this.$emit("update:order", value);
      },
    },
    informationType() {
      return this.$route.params.service.includes("pickup")
        ? "pickup"
        : "delivery";
    },
    personnel() {
      return this.$route.params.service.includes("cleaning")
        ? "cleaner"
        : "rider";
    },
    actions() {
      return [
        {
          access: ["superadmin", "admin", "growth", "gardener", "gardener_pro"],
          label: `Edit ${this.informationType} address`,
          name: "edit-address",
          component: "order-delivery-information-edit",
        },
        {
          access: ["superadmin", "admin", "growth", "gardener", "gardener_pro"],
          label: `${this.order.status === "unassigned" ? "Assign" : "Change"} ${
            this.personnel
          }`,
          name: `assign-${this.personnel}`,
          component: `assign-${this.personnel}`,
        },
      ];
    },
  },
  methods: {
    command(command) {
      this.action.command = command.action;
      this.action.component = command.component;
      setTimeout(() => {
        this.action.visibility = true;
      }, 100);
    },
    updateAddress({ address, locationarea }) {
      this.setOrder.address = address;
      this.setOrder.locationarea = locationarea;
    },
    updatePersonnel(personnel) {
      this.setOrder.status = "assigned";
      this.setOrder.personnel = personnel;
    },
  },
};
</script>

<style lang="scss"></style>
