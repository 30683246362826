<template>
  <el-dropdown trigger="click" :hide-on-click="true">
    <el-button type="plain">
      <span>
        Location: <span> {{ selectedLocationsLabel }} </span>
        <i class="eden-icon-arrow-down" />
      </span>
    </el-button>
    <el-dropdown-menu slot="dropdown">
      <div class="dropdown-search" v-loading="loading">
        <div class="dropdown-search--field">
          <el-button
            v-if="setSelectedLocations.length"
            type="text"
            color="primary"
            @click="setSelectedLocations = []"
          >
            Deselect All</el-button
          >
          <el-input
            v-model="search"
            type="text"
            :prefix-icon="'el-icon-search'"
            placeholder="Search"
          />
        </div>
        <div class="dropdown-search--list">
          <el-checkbox-group v-model="setSelectedLocations">
            <div
              v-for="(location, index) in locationareas"
              :key="index"
              class="location"
            >
              <el-checkbox :label="{ name: location.name, id: location.id }">
                {{ location.name }}
              </el-checkbox>
            </div>
          </el-checkbox-group>
        </div>
      </div>
    </el-dropdown-menu>
  </el-dropdown>
</template>

<script>
import * as actions from "@/store/action-types";

export default {
  name: "LocationAreasFilter",
  props: {
    selectedLocations: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      loading: false,
      search: "",
      location: "all",
    };
  },
  computed: {
    setSelectedLocations: {
      get() {
        return this.selectedLocations;
      },
      set(value) {
        this.$emit("update:selectedLocations", value);
      },
    },
    locationareas() {
      let locationareas = this.parseData(
        this.$store.getters.location_areas_list
      );
      if (this.search !== "") {
        return locationareas.filter((gardener) =>
          gardener.name.toLowerCase().includes(this.search.toLowerCase())
        );
      } else {
        return locationareas;
      }
    },
    selectedLocationsLabel() {
      if (!this.setSelectedLocations.length) {
        return "All";
      }
      const length = this.setSelectedLocations.length;
      const first = this.setSelectedLocations[0].name;
      return length > 1 ? `${first} + ${length - 1}` : first;
    },
  },

  created() {
    if (!this.locationareas.length) {
      this.$store.dispatch(actions.GET_LOCATION_AREAS_LIST);
    }
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.dropdown-search {
  width: 200px;
  &--field {
    padding: 0 10px 10px;
  }

  &--list {
    padding: 0 10px 10px;
    max-height: 250px;
    overflow-y: scroll;

    .location {
      margin-bottom: 10px;
    }
  }
}
</style>
