<template>
  <div>
    <eden-page-header :title="title" :subtitle="subtitle">
      <template slot="actions">
        <el-button class="mx-0" type="primary">Save Schedule </el-button>
      </template>
    </eden-page-header>
    <schedule-dates @set-date="setDate" />
    <schedule-orders :service="service" :date="date" />
  </div>
</template>

<script>
import ScheduleDates from "@/components/Logistics/Schedule/Create/ScheduleDates";
import ScheduleOrders from "@/components/Logistics/Schedule/Create/ScheduleOrders";

export default {
  name: "ScheduleCreate",
  components: { ScheduleDates, ScheduleOrders },
  data() {
    return {
      labels: {
        beauty: "Create Beauty Schedule",
        cleaning: "Create Cleaning Schedule",
        "laundry-pickup": "Create Laundry Pickup Schedule",
        "laundry-delivery": "Create Laundry Delivery Schedule",
        meal: "Create Food Delivery Schedule",
      },
      service: this.$route.params.service,
      date: "",
    };
  },

  computed: {
    title() {
      return "Schedule";
    },
    subtitle() {
      return this.labels[this.service] || "";
    },
  },
  methods: {
    setDate(date) {
      this.date = date;
      console.log(date);
    },
  },
};
</script>
