<template>
  <div>
    <eden-table-actions :title="title">
      <template slot="actions">
        <template v-if="selectedOrders.length">
          <schedule-orders-actions
            :service="service"
            :selected-orders="selectedOrders"
            @assign-personnel="assignPersonnel"
          />
        </template>
        <el-dropdown v-else>
          <el-button type="plain">
            <span> {{ types[type] }} <i class="eden-icon-arrow-down" /> </span>
          </el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item
              v-for="(value, key, i) in types"
              :key="i"
              :command="key"
              :class="{ selected: key === type }"
              >{{ value }}</el-dropdown-item
            >
          </el-dropdown-menu>
        </el-dropdown>
      </template>
    </eden-table-actions>
    <el-table
      :data="pageData"
      :key="rebuildTable"
      @select-all="setSelectedOrders"
      @selection-change="setSelectedOrders"
    >
      <el-table-column type="selection" width="55" />
      <el-table-column width="150">
        <template slot="header">
          <span>{{ service === "cleaning" ? "Cleaner" : "Rider" }}</span>
        </template>
        <template slot-scope="scope">
          <schedule-order-status
            :status="scope.row.status"
            :personnel="scope.row.personnel"
          />
        </template>
      </el-table-column>
      <template v-if="service !== 'meal'">
        <el-table-column width="150">
          <template slot="header">
            <div class="table--header">
              <span>Type</span>
              <i class="el-icon-bottom"></i>
            </div>
          </template>
          <template slot-scope="scope">
            <p>
              {{ unformatSlug(scope.row.type) }}
            </p>
          </template>
        </el-table-column>
      </template>
      <el-table-column width="100">
        <template slot="header">
          <span>{{ quantityColumn }}</span>
        </template>
        <template slot-scope="scope">
          <p>
            {{ scope.row.quantity }}
          </p>
        </template>
      </el-table-column>
      <el-table-column width="150">
        <template slot="header">
          <span>Location Area</span>
        </template>
        <template slot-scope="scope">
          <span class="font-sm">
            {{ scope.row.locationarea.name }}
          </span>
        </template>
      </el-table-column>
      <el-table-column width="250">
        <template slot="header">
          <span>Address</span>
        </template>
        <template slot-scope="scope">
          <span class="font-sm">
            {{ scope.row.address }}
          </span>
        </template>
      </el-table-column>
      <el-table-column width="200">
        <template slot="header">
          <div class="table--header">
            <span>Customer</span>
            <i class="el-icon-bottom"></i>
          </div>
        </template>
        <template slot-scope="scope">
          <p class="font-sm">{{ scope.row.customer }}</p>
          <span class="font-xsm text-grey-tertiary">
            {{ scope.row.gardener }}</span
          >
        </template>
      </el-table-column>
      <el-table-column width="150">
        <template slot="header">
          <div class="table--header">
            <span>Date</span>
            <i class="el-icon-bottom"></i>
          </div>
        </template>
        <template slot-scope="scope">
          <p>
            {{ formatDate(scope.row.date, "do m, y") }}
          </p>
        </template>
      </el-table-column>
      <el-table-column width="60">
        <template slot-scope="scope">
          <schedule-order-actions :order="scope.row" />
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import ScheduleOrdersActions from "@/components/Logistics/Schedule/Create/ScheduleOrdersActions";
import ScheduleOrderActions from "@/components/Logistics/Schedule/Create/ScheduleOrderActions";
import ScheduleOrderStatus from "@/components/Logistics/Schedule/Create/ScheduleOrderStatus";

import * as actions from "@/store/action-types";
import { parseScheduleOrders } from "./schedule.config";

export default {
  name: "ScheduleOrders",
  props: {
    service: {
      type: String,
      required: true,
    },
    date: {
      type: [String, Date],
      required: true,
    },
  },
  components: {
    ScheduleOrdersActions,
    ScheduleOrderActions,
    ScheduleOrderStatus,
  },
  data() {
    return {
      page: 1,
      pageData: [],
      type: "all",
      types: {
        all: "All Orders",
        unassigned: "Unassigned Orders",
        assigned: "Assigned Orders",
      },
      rebuildTable: false,
      selectedOrders: [],
    };
  },
  computed: {
    title() {
      return `${this.pageData.length} Orders`;
    },
    quantityColumn() {
      switch (this.service) {
        case "beauty":
          return "No of Variations";
        case "cleaning":
          return "No of Rooms";
        case "laundry-pickup":
          return "No of Bags";
        case "laundry-delivery":
          return "No of Bags";
        case "meal":
          return "No of Meals";
        default:
          return "";
      }
    },
  },
  watch: {
    date() {
      // this.getOrders();
      this.setPageData();
    },
  },
  methods: {
    getOrders() {
      this.$store.dispatch(actions.GET_LOGISTICS_SCHEDULE_ORDERS, {
        service: this.service,
        date: this.date,
        page: this.page,
      });
    },
    setPageData() {
      this.pageData = parseScheduleOrders(this.service);
    },
    setSelectedOrders(orders) {
      this.selectedOrders = [];
      orders.forEach((order) => {
        this.selectedOrders.push(order.id);
      });
    },
    assignPersonnel({ status, personnel }) {
      this.pageData.forEach((order, index) => {
        if (this.selectedOrders.includes(order.id)) {
          this.pageData[index].status = status;
          this.pageData[index].personnel = personnel;
        }
      });
      this.selectedOrders = [];
      this.rebuildTable = !this.rebuildTable;
    },
  },
};
</script>
